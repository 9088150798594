import { Point } from './point'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Boundaries {
  protected latMin: number
  protected latMax: number
  protected lngMin: number
  protected lngMax: number

  constructor (latMin: number, latMax: number, lngMin: number, lngMax: number) {
    this.latMin = latMin
    this.latMax = latMax
    this.lngMin = lngMin
    this.lngMax = lngMax
  }

  /**
   * Combines all boundaries in object.
   */
  get all (): Record<string, Point> {
    return {
      nw: this.nw,
      ne: this.ne,
      sw: this.sw,
      se: this.se
    }
  }

  /**
   * Describes if Boundaries are equivalent to point.
   */
  get arePoint (): boolean {
    return this.valid &&
      this.latMin === this.latMax &&
      this.lngMin === this.lngMax
  }

  /**
   * Returns Point representing the boundaries center.
   */
  get center (): Point {
    if (!this.valid) {
      throw new TypeError('Given boundaries are not valid. Use "valid" getter before invoking "center"')
    }

    if (this.arePoint) {
      return this.sw
    }

    return new Point(
      (this.nw.lat + this.se.lat) / 2,
      (this.nw.lng + this.se.lng) / 2
    )
  }

  /**
   * Alias for ne.
   */
  get getNorthEast (): Point {
    return this.ne
  }

  /**
   * Alias for nw.
   */
  get getNorthWest (): Point {
    return this.nw
  }

  /**
   * Alias for se.
   *
   * @returns  {this.se}
   */
  get getSouthEast () {
    return this.se
  }

  /**
   * Alias for sw.
   */
  get getSouthWest (): Point {
    return this.sw
  }

  /**
   * The north-eastern Point.
   */
  get ne (): Point {
    return new Point(this.latMax, this.lngMax)
  }

  /**
   * The north-western Point.
   */
  get nw (): Point {
    return new Point(this.latMax, this.lngMin)
  }

  /**
   * The south-eastern Point.
   */
  get se (): Point {
    return new Point(this.latMin, this.lngMax)
  }

  /**
   * The south-western Point.
   */
  get sw (): Point {
    return new Point(this.latMin, this.lngMin)
  }

  /**
   * Describes if boundaries can be presented on map.
   */
  get valid (): boolean {
    return this.latMin > -1 &&
      this.latMax > -1 &&
      this.lngMin > -1 &&
      this.lngMax > -1
  }
}
