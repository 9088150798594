

















import { Component, Prop, Vue } from 'vue-property-decorator'

import { InPostModalTab, InPostParcel } from '../InPostModal.contracts'

@Component({
  name: 'ParcelListItem'
})
export class ParcelListItem extends Vue {
  @Prop({ type: Object, required: true })
  public readonly parcel!: InPostParcel

  public onClick (): void {
    this.$emit('click', this.parcel, InPostModalTab.List)
  }

  public onHover (): void {
    this.$emit('hover', this.parcel)
  }

  public onLeave (): void {
    this.$emit('leave')
  }
}

export default ParcelListItem
