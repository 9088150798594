
























import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 */
@Component
export class InPostHours extends Vue {
  @Prop({ required: true })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public readonly hours: any

  public closedDays: string[] = []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected readonly days: any[] = [
    { key: 'pn', day: 'Monday' },
    { key: 'wt', day: 'Tuesday' },
    { key: 'sr', day: 'Wednesday' },
    { key: 'cz', day: 'Thursday' },
    { key: 'pt', day: 'Friday' },
    { key: 'sb', day: 'Saturday' },
    { key: 'nd', day: 'Sunday' }
  ]

  public get parseOpeningHours () {
    if (this.hours === '24/7') {
      return this.hours
    }

    const parsed = this.hours.split(' ')
    const res = []

    let lastDay = ''
    for (let i = 0; i < parsed.length / 2; i++) {
      const days = parsed[2 * i].split('-')
      const hours = parsed[2 * i + 1].split('-')

      lastDay = days.length > 1 ? days[1].toLowerCase() : days[0].toLowerCase()

      const obj = {
        days: `${this.$t(
          this.days.find(day => day.key === days[0].toLowerCase()).day
        )}${
          days.length > 1
            ? ' - ' +
            this.$t(
              this.days.find(day => day.key === days[1].toLowerCase()).day
            )
            : ''
        }`,
        hours: {
          from: hours[0].indexOf(':') > 0 ? hours[0] : hours[0] + ':00',
          to: hours[1].indexOf(':') > 0 ? hours[1] : hours[1] + ':00'
        }
      }

      res.push(obj)
    }

    this.fillClosedDays(lastDay)

    return res
  }

  protected fillClosedDays (lastDay: string): void {
    if (lastDay === 'nd') {
      return
    }

    const index = this.days.indexOf(this.days.find(day => day.key === lastDay))
    const nextDay = this.days[index + 1].key
    const isWorkDay = ['pn', 'wt', 'sr', 'cz'].includes(nextDay)

    if (isWorkDay) {
      this.closedDays.push(
        `${this.$t(this.days[index + 1])} - ${this.$t(
          this.days.find(day => day.key === 'pt').day
        )}`
      )
    }

    if (nextDay === 'pt') {
      this.closedDays.push(
        this.$t(this.days.find(day => day.key === 'pt').day).toString()
      )
    }

    if (nextDay === 'pt' || nextDay === 'sb' || isWorkDay) {
      this.closedDays.push(
        this.$t(this.days.find(day => day.key === 'sb').day).toString()
      )
    }

    if (nextDay === 'nd' || nextDay === 'pt' || nextDay === 'sb' || isWorkDay) {
      this.closedDays.push(
        this.$t(this.days.find(day => day.key === 'nd').day).toString()
      )
    }
  }
}

export default InPostHours
