/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const INPOST_MODAL_COMPONENT_KEY = 'InPostModalComponent'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const INPOST_MODAL_DEFAULT_CONFIG = {
  buttonIcon: null,
  isSearchByPostcode: true,
  showPaymentOptions: true
}
